import { Node, mergeAttributes } from "@tiptap/core";
import FileHandler from '@tiptap-pro/extension-file-handler'

interface ImageAttributes {
  src: string;
  alt?: string | null;  // Allow `undefined`, `string`, or `null`
  title?: string | null; // Allow `undefined`, `string`, or `null`
}

export const fileHandler = (callBack: Function) => {
  return FileHandler.configure({
    allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
    onPaste: (currentEditor, files) => {
      files.forEach(file => {
        callBack(file, currentEditor);
      })
    },
    onDrop: (currentEditor, files, pos) => {
      files.forEach(file => {
        callBack(file, currentEditor);
      })
    }
  });
}

export const CustomImage = Node.create({
  name: "image",
  inline: true,
  group: "inline",
  draggable: true,

  addAttributes() {
    return {
      src: {},
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "img[src]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "a",
      { href: HTMLAttributes.src, target: "_blank", rel: "noopener noreferrer" },
      ["img", mergeAttributes(HTMLAttributes)],
    ];
  },

  addCommands() {
    return {
      setImage: (options: ImageAttributes) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options,
        });
      },
    };
  },
});

export const convertUrlToLink = (editor: any) => {
  editor?.on('paste', ({ event }: any) => {
  const clipboardData = event.clipboardData
  const pastedText = clipboardData.getData('text');

  if (pastedText) {
      const urlPattern = new RegExp(
        'https?:\\/\\/(?:www\\.|(?!www))[^\\s.]+\\.[^\\s]{2,}|www\\.[^\\s]+\\.[^\\s]{2,}'
      )

      if (urlPattern.test(pastedText)) {
        editor?.chain().focus().extendMarkRange('link').setLink({ href: pastedText }).run()
      }
    }
  })
}